<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!--        <vuexy-logo />-->
        <h2 class="brand-text text-primary ml-1">
          中国科学院金属研究所
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            欢迎来到金属所人事易评 IMR! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            请登录您的账号
          </b-card-text>

          <b-tabs pills>
            <b-tab
                title="验证码登录"
                active
            >
          <!-- form -->
          <validation-observer ref="loginValidationCode">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="账号"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="您的手机号"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>



              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="">短信验证码</label>
                </div>

                <validation-provider
                    #default="{ errors }"
                    name="code"
                    rules="required"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input

                        v-model="sms_code"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"

                    />
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click="sendSms">
                        发送验证码
                      </b-button>
                    </b-input-group-append>

                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>

              <!-- checkbox -->

              <!-- submit buttons -->
              <b-button
                type="button"
                variant="primary"
                block
                @click="validationForm"
              >
                登录
              </b-button>


            </b-form>
          </validation-observer>
            </b-tab>
            <b-tab title="账号密码登录">
              <validation-observer ref="loginValidation">
                <b-form
                    class="auth-login-form mt-2"
                    @submit.prevent
                >
                  <!-- email -->
                  <b-form-group
                      label="账号"
                      label-for="login-email"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                    >
                      <b-form-input

                          v-model="name"
                          :state="errors.length > 0 ? false:null"
                          name="name"
                          placeholder="输入账号...."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">密码</label>
                    </div>
                    <validation-provider
                        #default="{ errors }"
                        name="password"
                        rules="required"
                    >
                      <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input

                            v-model="password"
                            :state="errors.length > 0 ? false:null"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="login-password"
                            placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">验证码</label>
                    </div>
                    <validation-provider
                        #default="{ errors }"
                        name="验证码"
                        rules="required"
                    >
                      <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input

                            v-model="code"
                            :state="errors.length > 0 ? false:null"
                            class="form-control-merge"

                            placeholder="输入验证码"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="">验证码图形</label>
                    </div>

                    <b-input-group
                        class="input-group-merge"
                    >
                      <img
                          :src="code_img"
                          @click="getVerifyCode"
                      >
                    </b-input-group>

                  </b-form-group>

                  <b-button
                      type="button"
                      variant="primary"
                      block
                      @click="validationFormPassword"
                  >
                    登录
                  </b-button>




                </b-form>
              </validation-observer>
            </b-tab>
          </b-tabs>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,BTab,BTabs
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BTab,BTabs,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      name: '',
      code:"",
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      code_img:"",
      sms_code:""
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  created() {
    this.ifDevice()
    this.getVerifyCode()
  },
  methods: {

    ifDevice(){
      var user = navigator.userAgent.toLowerCase();
      if (user.indexOf('harmonyos') === 0) {

      }
    },

    getVerifyCode() {
      this.UserVerifyCode().then(res => {
        this.code_id = res.data.data.id
        this.code_img = res.data.data.path
      })
    },

    sendSms(){
      this.UserLoginCode({ phone: this.name }).then(res => {
        this.showToast(res.data.code,res.data.msg)
      })
    },

    validationFormPassword(){
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.UserLoginPassword({ phone: this.name,password:this.password,code_id :this.code_id,code:this.code }).then(res => {
            console.log(res)
            if (res.data.code === 200) {
              const { data } = res.data
              localStorage.setItem('uid', data.user.id)
              localStorage.setItem('token', data.token)
              localStorage.setItem('teacher', JSON.stringify(data.user))
              this.$router.push({ name: 'awards-lists' })
              // if (this.isMobile()){
              //   this.$router.push({ name: 'awards-lists' })
              // }else{
              //   this.$router.push({ name: 'home' })
              // }

            } else {
              console.log(res.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: '错误',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: res.data.msg,
                },
              })
            }
          })
        }
      })
    },
    validationForm() {

      this.$refs.loginValidationCode.validate().then(success => {
        if (success) {
          this.UserLogin({ phone: this.name,code:this.sms_code }).then(res => {
            console.log(res)
            if (res.data.code === 200) {
              const { data } = res.data
              localStorage.setItem('uid', data.user.id)
              localStorage.setItem('token', data.token)
              localStorage.setItem('teacher', JSON.stringify(data.user))
              this.$router.push({ name: 'awards-lists' })
                // if (this.isMobile()){
                //   this.$router.push({ name: 'awards-lists' })
                // }else{
                //   this.$router.push({ name: 'home' })
                // }

            } else {
              console.log(res.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: '错误',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: res.data.msg,
                },
              })
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
