var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" 中国科学院金属研究所 ")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" 欢迎来到金属所人事易评 IMR! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" 请登录您的账号 ")]),_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"title":"验证码登录","active":""}},[_c('validation-observer',{ref:"loginValidationCode"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"账号","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false:null,"name":"login-email","placeholder":"您的手机号"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":""}},[_vm._v("短信验证码")])]),_c('validation-provider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.sms_code),callback:function ($$v) {_vm.sms_code=$$v},expression:"sms_code"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.sendSms}},[_vm._v(" 发送验证码 ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"type":"button","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v(" 登录 ")])],1)],1)],1),_c('b-tab',{attrs:{"title":"账号密码登录"}},[_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"账号","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"name","placeholder":"输入账号...."},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("密码")])]),_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("验证码")])]),_c('validation-provider',{attrs:{"name":"验证码","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"state":errors.length > 0 ? false:null,"placeholder":"输入验证码"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":""}},[_vm._v("验证码图形")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('img',{attrs:{"src":_vm.code_img},on:{"click":_vm.getVerifyCode}})])],1),_c('b-button',{attrs:{"type":"button","variant":"primary","block":""},on:{"click":_vm.validationFormPassword}},[_vm._v(" 登录 ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }